const locationListCardTemplate = (location, show_asset) => {
  const { id, assets } = location
  const templateHtml = `
    <div class="sidebar-card">
      <div class="main-card">
        <a data-turbo="false" href="/location_management/locations/${id}">${cardContentTempelate(location)}</a>
      </div>
      ${assets.length == 0 ? '' : locationListAssetsTemplate(assets, show_asset)}
    </div>`

  const wrapperDiv = document.createElement('div')
  wrapperDiv.innerHTML = templateHtml
  return wrapperDiv
}

const cardContentTempelate = (location) => {
  const { name, location_type, humanized_location_type, assets } = location
  const cardContentTempelateHtml = `
    <div class="card-title">
      ${window.mapbox_data.map_data.location_svg[location_type]}
      <span>${humanized_location_type}</span>
      ${assets.length == 0 ? "" : `<span>(${assets.length} assets)</span>`}
    </div>
    <div class="card-body">
      <h6>${name}</h6>
      ${locationStateTempelate(location)}
    </div>`

  return cardContentTempelateHtml
}

const locationStateTempelate = ({ state, state_tag, state_timestamp, is_legacy, kg_asset_name }) => {
  let locationStateTempelateHtml = ''

  if (is_legacy) {
    console.log('kg_asset_name', kg_asset_name)
    locationStateTempelateHtml = `
      <div>
        <div><span class="text-sm font-semibold text-secondary text-end">AssetName: ${kg_asset_name ? kg_asset_name : 'N/A'}</span></div>
      </div>`
  } else {
    locationStateTempelateHtml = `
      <div class="status-bar">
        <div><span class="tag ${state_tag}-tag">${state}</span></div>
        ${
          state === 'Healthy'
          ? ''
          : `<div><span class="timestamp timestamp-${state.toLowerCase()}">Since: ${state_timestamp}</span></div>`
        }
      </div>`
  }

  return locationStateTempelateHtml
}

const locationListAssetsTemplate = (assets, show_asset) => {
  const assetsHtml = `
    <div class="secondry-card ${show_asset ? '' : 'hidden'}">
      <table class="asset-table">
        <tbody>
          ${assets.map(asset => locationAssetTemplate(asset)).join('')}
        </tbody>
      </table>
    </div>`

  return assetsHtml
}

const locationAssetTemplate = ({ asset_type, state, state_tag }) => {
  return `
    <tr>
      <td>${asset_type}</td>
      <td></td>
      <td>
          <span class="tag ${state_tag}-tag">
            ${state}
          </span>
      </td>
    </tr>`
}

export { locationListCardTemplate }
