import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import log from '../utils/log'

export default class extends Controller {
  static targets = ['legacyLocationToggler', 'stagingLocationToggler'];
  static values = { targetId: String };

  async connect () {
    this.tomSelect = new TomSelect(`#${this.targetIdValue}`, await this.selectorConfig());
    document.getElementById('search-select-ts-control').addEventListener('input', this.handleDebounceInput());
  }

  handleDebounceInput(){
    let timeoutId;
    return (event) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        this.handleInputChange(event.target.value);
      }, 500);
    }
  }

  disconnect() {
    this.tomSelect.destroy()
    document.getElementById('search-select-ts-control').removeEventListener('input', this.handleDebounceInput());
    window.searchParams = {}
  }

  async selectorConfig() {
    const {
      labelField,
      options,
      optgroups,
      optgroupField,
      searchField,
      valueField
    } = await this.searchParams()
    this.filterValues = Array.from(new Set(options.flatMap(option => option.map(item => item.name.toLowerCase()))))

    return {
      closeAfterSelect: true,
      labelField: labelField,
      optgroups: optgroups,
      optgroupField: optgroupField,
      options: options,
      placeholder: 'Search or select form filter',
      plugins: ['remove_button'],
      searchField: searchField,
      valueField: valueField,
      onChange: options => this.handleFilterChange(options),
      render: {
        item:(data) => `<div>${data.type}: ${data.name}</div>`,
        no_results:() => ''
      }
    }
  }

  async searchParams() {
    try {
      const response = await fetch('/location_management/api/v1/locations/search_params')
      return await response.json()
    } catch (e) {
      log.debug(e)
    }
  }

  updateLegacyLocations() {
    window.searchParams ||= {}
    window.searchParams['enable_legacy_location'] = [this.legacyLocationTogglerTarget.checked]
    this.triggerChangeEvent()
  }

  updateStagingLocation() {
    window.searchParams ||= {}
    window.searchParams['enable_staging_location'] = [this.stagingLocationTogglerTarget.checked]
    this.triggerChangeEvent()
  }


  handleFilterChange (options) {
    window.searchParams = {}
    window.searchParams['enable_legacy_location'] = [this.legacyLocationTogglerTarget.checked]
    window.searchParams['enable_staging_location'] = [this.stagingLocationTogglerTarget.checked]

    options.forEach(opt => {
      const [type, value] = opt.split("|")

      window.searchParams[type] ||= []
      window.searchParams[type].push(value)
    })

    Object.keys(window.searchParams).forEach((key) => {
      window.searchParams[key] = window.searchParams[key].join(",")
    });

    this.triggerChangeEvent()
  }

  handleInputChange(value) {
    if (value !== "" && this.filterValues.some(item => item.toLowerCase().includes(value.toLowerCase()))) {
      return;
    }

    window.searchParams ||= {}
    window.searchParams["search_attributes"] = value
    this.triggerChangeEvent()
  }

  triggerChangeEvent() {
    const event = new CustomEvent("updateSearch")
    window.dispatchEvent(event)
  }
}
