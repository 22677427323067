import { Controller } from '@hotwired/stimulus'
import { initLocationMap, addMarker } from '../utils/map'
export default class extends Controller {
  static values = {
    location: Object,
    iconType: String,
    targetId: String,
  };

  async connect() {
    const coordinates = [this.locationValue.longitude, this.locationValue.latitude]
    this.map = initLocationMap(this.targetIdValue, coordinates, 10, false)
    addMarker(this.map, coordinates, this.iconTypeValue, false)
  }
}
