import mapboxgl from 'mapbox-gl'

const { cluster_properties, cluster_conditions, map_icons, map_icon_size } = window.mapbox_data.map_data

const dispatchUpdateMap = function () {
  const event = new CustomEvent('updateMap')
  window.dispatchEvent(event)
}

const initLocationMap = (id, coordinates, zoom, hash) => {
  mapboxgl.accessToken = window.mapbox_data.access_token
  const map = new mapboxgl.Map({
    container: id,
    style: 'mapbox://styles/mapbox/streets-v9',
    center: coordinates,
    zoom: zoom,
    hash: hash
  })
  map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  return map
}

const renderMapLocations = (map, locations, show_popup = true) => {
  const mapData = createFeatureCollection(locations)

  map.loaded()
    ? loadMapData(map, mapData, show_popup)
    : map.on('load', () => loadMapData(map, mapData, show_popup))
}

loadMapData = function (map, mapData, show_popup) {
  loadMapImages(map, map_icons).then(() => {
    map.addSource('mapData', {
      type: 'geojson',
      data: mapData,
      cluster: true,
      clusterMaxZoom: 18,
      clusterRadius: 50,
      clusterProperties: cluster_properties
    })

    map.addLayer({
      id: 'clusters',
      type: 'symbol',
      source: 'mapData',
      filter: ['has', 'point_count'],
      layout: {
        'icon-image': cluster_conditions,
        'icon-size': map_icon_size,
        'icon-allow-overlap': true,
      },
    })

    map.addLayer({
      id: 'clusters-count-bg',
      type: 'circle',
      source: 'mapData',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#51bbd6',
        'circle-radius': 8,
        'circle-translate': [-15, -15],
      },
    })

    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'mapData',
      filter: ['has', 'point_count'],
      paint: {
        'text-translate': [-15, -15],
      },
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
        'text-allow-overlap': true,
      },
    })

    map.addLayer({
      id: 'unclustered-point',
      type: 'symbol',
      source: 'mapData',
      layout: {
        'icon-image': ['get', 'icon_type'],
        'icon-size': map_icon_size,
        'icon-allow-overlap': true,
      },
    })

    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] })
      const clusterId = features[0].properties.cluster_id
      map.getSource('mapData').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return

        map.easeTo({ center: features[0].geometry.coordinates, zoom: zoom })
      })
    })

    if (show_popup === true) {
      map.on('click', 'unclustered-point', (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice()
        const { id, name, humanized_location_type, state, state_tag, assets } = e.features[0].properties
        const assets_count = JSON.parse(assets)?.length

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360

        const mapPinHtml = `
          <div>
            <h6>${name}</h6>
            <div class="flex justify-between items-center mt-1 p2">
              <span>${humanized_location_type} ${assets_count === 0 ? '' : `(${assets_count})`}</span>
              <span class="ml-3 tag ${state_tag}-tag">${state}</span>
            </div>
            <span><a class="hover:underline text-primary" href="/location_management/locations/${id}">Visit Location</a></span>
          </div>`
        new mapboxgl.Popup().setLngLat(coordinates).setHTML(mapPinHtml).addTo(map)
      })
    }

    map.on('mouseenter', 'clusters', () => map.getCanvas().style.cursor = 'pointer' )
    map.on('mouseleave', 'clusters', () => map.getCanvas().style.cursor = '' )
    map.on('moveend', dispatchUpdateMap)
  })
}

const loadMapImages = (map, map_icons) => {
  const addImage = (map, id, url) => {
    return new Promise((resolve, reject) => {
      map.loadImage(url, (error, image) => {
        if (error) {
          reject(error)
          return
        }
        map.addImage(id, image)
        resolve(image)
      })
    })
  }

  const promises = map_icons.map((imageData) => addImage(map, imageData.id, imageData.url))
  return Promise.all(promises)
}

const updateMapLocations = (map, locations) => {
  const mapData = createFeatureCollection(locations)
  map.getSource('mapData').setData(mapData)
}

const createFeatureCollection = (locations) => {
  return {
    type: 'FeatureCollection',
    features: locations.map((location) => createFeature(location))
  }
}

const createFeature = (location) => {
  return {
    type: 'Feature',
    properties: location,
    geometry: {
      type: 'point',
      coordinates: [location.longitude, location.latitude]
    }
  }
}

const addMarker = (map, coordinates, location_type, draggable) => {
  const icon = document.createElement('div')
  icon.innerHTML = `<img src="${map_icons.find(obj => obj.id === location_type)?.url}" width="32" height="32" />`

  new mapboxgl.Marker({
    element: icon,
    draggable: draggable,
  }).setLngLat(coordinates).addTo(map)
}

export { addMarker, initLocationMap, renderMapLocations, updateMapLocations }
