import { Controller } from '@hotwired/stimulus'
import { initLocationMap, renderMapLocations, updateMapLocations } from '../utils/map'
import { locationListCardTemplate } from "../utils/template"
import { get } from "@rails/request.js";

const CHUNK_SIZE = 25;
const DEFAULT_MAP_ZOOM = 10;

export default class extends Controller {
  static targets = ['showAssetToggler', 'sideBar', 'locationList'];
  static values = { targetId: String };

  pageNumber = 0;
  searchParams = {};
  locationsList = [];

  async connect () {
    this.map = initLocationMap(this.targetIdValue, window.mapbox_data.coordinates, DEFAULT_MAP_ZOOM, true)
    await this.renderMap()
  }

  bounds() {
    const {
      _ne: { lat: latitude_1, lng: longitude_1 },
      _sw: { lat: latitude_2, lng: longitude_2 }
    } = this.map.getBounds();

    return {
      coordinates: true,
      latitude_1,
      longitude_1,
      latitude_2,
      longitude_2,
    };
  }

  scrolled() {
    const { bottom } = this.locationListTarget.getBoundingClientRect();

    if (this.sideBarTarget.clientHeight + this.sideBarTarget.offsetTop > bottom ) {
      this.pageNumber += 1
      const locations = this.locationsChunk()
      this.loadLocations(locations, true);
    }
  }

  async renderMap() {
    const res = await this.fetchLocations();
    this.locationsList = res.locations;

    const locations = this.locationsChunk();
    this.loadLocations(locations);
    renderMapLocations(this.map, this.locationsList);
  }

  locationsChunk() {
    const initialValue = this.pageNumber * CHUNK_SIZE
    return this.locationsList.slice(initialValue, initialValue + CHUNK_SIZE)
  }

  async updateMap(e) {
    this.searchParams = window.searchParams ?? {};
    const res = await this.fetchLocations();
    this.locationsList = res.locations;

    const locations = this.locationsChunk();
    this.loadLocations(locations);
    updateMapLocations(this.map, this.locationsList);
  }

  loadLocations(locations, append=false) {
    if (!append) {
      this.pageNumber = 0
      this.locationListTarget.innerHTML = ""
    }
    const showAsset = this.showAssetTogglerTarget.checked
    locations.forEach( location => {
      const locationTemplate = locationListCardTemplate(location, showAsset)
      this.locationListTarget.appendChild(locationTemplate)
    })
  }

  updateShowAssets() {
    const assetsCard = document.querySelectorAll('#location-list .sidebar-card .secondry-card')
    const showAsset = this.showAssetTogglerTarget.checked
    assetsCard.forEach( assetsCard => {
      if (showAsset) {
        assetsCard.classList.remove('hidden')
      } else {
        assetsCard.classList.add('hidden')
      }
    })
  }

  async fetchLocations() {
    this.sideBarTarget.scrollTo(0,0)

    const response = await get("/location_management/api/v1/locations", {
      query: {
        ...this.bounds(),
        ...this.searchParams
      }
    });

    return await response.json;
  }
}
